<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_eng" placeholder="Enter firstname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="นามสกุล (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_eng" placeholder="Enter firstname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_th" placeholder="Enter lastname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="นามสกุล (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_th" placeholder="Enter firstname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2"
                                label="ตำแหน่งวิชาการ (Eng)" label-for="input-2">
                                <b-form-input id="input-2" v-model="academic_position_eng" placeholder="Enter lastname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2"
                                label="ตำแหน่งวิชาการ (Th)" label-for="input-2">
                                <b-form-input id="input-2" v-model="academic_position_th" placeholder="Enter firstname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="สังกัด (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_eng" placeholder="Enter lastname"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="สังกัด (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_th"
                                    placeholder="Enter firstname"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2"
                                label="ความเชี่ยวชาญ (Eng)" label-for="input-2">
                                <b-form-input id="input-2" v-model="expertise_eng"
                                    placeholder="Enter lastname"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2"
                                label="ความเชี่ยวชาญ (Th)" label-for="input-2">
                                <b-form-input id="input-2" v-model="expertise_th"
                                    placeholder="Enter lastname"></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(updateExpert())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import { validEmail } from "../../../../../helpers/validation";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import expertService from "../../../../../services/expert.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            expertId: null,
            firstname_eng: null,
            lastname_eng: null,
            firstname_th: null,
            lastname_th: null,
            academic_position_eng: null,
            academic_position_th: null,
            academic_position_th: null,
            affiliation_th: null,
            expertise_eng: null,
            expertise_th: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การตั้งค่า",
                    href: "#",
                },
                {
                    text: "ผู้ทรงคุณวุฒิ",
                    to: { name: "experts" },
                },
                {
                    text: "แก้ไขผู้ทรงคุณวุฒิ",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getExpert: async function () {
            this.expertId = this.$route.params.id;
            let response = await expertService.getExpertById(this.expertId);
            let data = response.data.expert;
            this.firstname_eng = data.firstname_eng;
            this.lastname_eng = data.lastname_eng;
            this.firstname_th = data.firstname_th;
            this.lastname_th = data.lastname_th;
            this.academic_position_eng = data.academic_position_eng;
            this.academic_position_th = data.academic_position_th;
            this.affiliation_eng = data.affiliation_eng;
            this.affiliation_th = data.affiliation_th;
            this.expertise_eng = data.expertise_eng;
            this.expertise_th = data.expertise_th;

        },

        updateExpert: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.expertId,
                        firstname_eng: this.firstname_eng,
                        lastname_eng: this.lastname_eng,
                        firstname_th: this.firstname_th,
                        lastname_th: this.lastname_th,
                        academic_position_eng: this.academic_position_eng,
                        academic_position_th: this.academic_position_th,
                        affiliation_eng: this.affiliation_eng,
                        affiliation_th: this.affiliation_th,
                        expertise_eng: this.expertise_eng,
                        expertise_th: this.expertise_th
                    }
                    await expertService.updateExpert(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; }, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }


            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }


        },
        isValidate() {
            this.errors = [];
            if (!this.firstname_eng) this.errors.push("Firstname Eng required.");
            if (!this.lastname_eng) this.errors.push("Lastname Eng required.");
            if (!this.firstname_th) this.errors.push("Firstname Th required.");
            if (!this.lastname_th) this.errors.push("Lastname Th required.");
            if (!this.academic_position_eng) this.errors.push("Academic position Eng required.");
            if (!this.academic_position_th) this.errors.push("Academic position Th required.");

            if (!this.errors.length) return true;
        },

    },
    mounted() {
        this.getExpert();
    }

};
</script>